import endpoints from "../models/api/api.endpoints";
import api from "../helpers/api.service";
import Helper from "../helpers/helper.service";
import ServiceResponseCode from "../models/service.response.code";

export default {
  async getDiciplinaLista(
  page = null,
  pageSize = null,
  disciplinaNome = null,
  modalidade = null,
  idCurso = null
  ) {
    try {
      const params = [
        {
          Name: 'page',
          Value: page
        },
        {
          Name: 'pageSize',
          Value: pageSize
        },
        {
          Name: 'DisciplinaNome',
          Value: disciplinaNome
        },
        {
          Name: 'modalidade',
          Value: modalidade
        },
        {
          Name: 'idCurso',
          Value: idCurso
        }
      ]
      const url = `${endpoints.disciplina}/list${Helper.getURLParams(params)}`;
      const result = await api.get(url)
      return Helper.getServiceResponse(result)
    } catch (e) {
      return {
        Code: ServiceResponseCode.CatchAPICall,
        Title: 'Error',
        Message: e.message,
        Success: false,
      }
    }
  },
  async getDisciplina(id) {
    try {
      const url = `${endpoints.disciplina}/${id}`;
      const result = await api.get(url)
      return Helper.getServiceResponse(result)
    } catch (e) {
      return {
        Code: ServiceResponseCode.CatchAPICall,
        Title: 'Error',
        Message: e.message,
        Success: false,
      }
    }
  },
  async registerDisciplina(data, method) {
    try {
      const result = (method == 'criar') ?
      await api.post(endpoints.disciplina, data) :
      await api.put(endpoints.disciplina, data)
      return Helper.getServiceResponse(result)
    } catch (e) {
      return {
        Code: ServiceResponseCode.CatchAPICall,
        Title: 'Error',
        Message: e.message,
        Success: false,
      }
    }
  },
  async getNavegarDisciplina(id) {
    try {
      const url = `${endpoints.disciplina}/navegar-disciplina/${id}`;
      const result = await api.get(url)
      return Helper.getServiceResponse(result)
    } catch (e) {
      return {
        Code: ServiceResponseCode.CatchAPICall,
        Title: 'Error',
        Message: e.message,
        Success: false,
      }
    }
  },
  async infoCopiaDisciplina(idDisciplina) {

    try {
      const url = `${endpoints.disciplina}/info-copia-disciplina/${idDisciplina}`;
      const result = await api.get(url)
      return Helper.getServiceResponse(result)
    } catch (e) {
      return {
        Code: ServiceResponseCode.CatchAPICall,
        Title: 'Error',
        Message: e.message,
        Success: false,
      }
    }

  },
  async getAndamentoAtividades(idModulo = null, idAtividade = null) {
    try {
      const url = `${endpoints.disciplina}/andamento/${(idAtividade ? 'atividade' : 'modulo')}/${idAtividade ?? idModulo}`;
      const result = await api.get(url)
      return Helper.getServiceResponse(result)
    } catch (e) {
      return {
        Code: ServiceResponseCode.CatchAPICall,
        Title: 'Error',
        Message: e.message,
        Success: false,
      }
    }
  },
  async removerAtividadeEmAndamento(idAtividade, idUsuario) {
    try {
      const params = [
        {
          Name: 'idAtividade',
          Value: idAtividade
        },
        {
          Name: 'idUsuario',
          Value: idUsuario
        },
      ]
      const url = `${endpoints.disciplina}/remover-atividade-em-andamento${Helper.getURLParams(params)}`;
      const result = await api.delete(url)
      return Helper.getServiceResponse(result)
    } catch (e) {
      return {
        Code: ServiceResponseCode.CatchAPICall,
        Title: 'Error',
        Message: e.message,
        Success: false,
      }
    }
  },
  async copiarDisciplina(idOrigem, idDestino = null) {
    try {

      const params = [
        {
          Name: 'idOrigem',
          Value: idOrigem
        },
        {
          Name: 'idDestino',
          Value: idDestino
        },
      ]
      const url = `${endpoints.disciplina}/copiar-disciplina${Helper.getURLParams(params)}`;
      const result = await api.post(url)
      return Helper.getServiceResponse(result)
    } catch (e) {
      return {
        Code: ServiceResponseCode.CatchAPICall,
        Title: 'Error',
        Message: e.message,
        Success: false,
      }
    }
  },

}
