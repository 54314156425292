import endpoints from "../models/api/api.endpoints";
import api from "../helpers/api.service";
import Helper from "../helpers/helper.service";
import ServiceResponseCode from "../models/service.response.code";

export default {
  async getBancoPerguntaLista(page = null, pageSize = null, pesquisa= null) {
    try {
      const params = [
        {
          Name: 'page',
          Value: page
        },
        {
          Name: 'pageSize',
          Value: pageSize
        },
        {
          Name: 'pesquisa',
          Value: pesquisa
        }
      ]
      const url = `${endpoints.bancoPergunta}/list${Helper.getURLParams(params)}`;
      const result = await api.get(url)
      return Helper.getServiceResponse(result)
    } catch (e) {
      return {
        Code: ServiceResponseCode.CatchAPICall,
        Title: 'Error',
        Message: e.message,
        Success: false,
      }
    }
  },
  async getBancoPergunta(id) {
    try {
      const url = `${endpoints.bancoPergunta}/${id}`;
      const result = await api.get(url)
      return Helper.getServiceResponse(result)
    } catch (e) {
      return {
        Code: ServiceResponseCode.CatchAPICall,
        Title: 'Error',
        Message: e.message,
        Success: false,
      }
    }
  },
  async registroBancoPergunta(data, method) {
    try {
      const result = (method == 'criar') ?
        await api.post(endpoints.bancoPergunta, data) :
        await api.put(endpoints.bancoPergunta, data)
      return Helper.getServiceResponse(result)
    } catch (e) {
      return {
        Code: ServiceResponseCode.CatchAPICall,
        Title: 'Error',
        Message: e.message,
        Success: false,
      }
    }
  },
  async copiarBancoPergunta(data) {
    try {
      const url = `${endpoints.bancoPergunta}/copiar-banco-pergunta`;
      const result = await api.post(url, data)
      return Helper.getServiceResponse(result)
    } catch (e) {
      return {
        Code: ServiceResponseCode.CatchAPICall,
        Title: 'Error',
        Message: e.message,
        Success: false,
      }
    }
  },
}
