<template>
  <div class="navbar-header">
<!--    <a id="mobile_btn" href="javascript:void(0);">-->
<!--        <span class="bar-icon">-->
<!--            <span></span>-->
<!--            <span></span>-->
<!--            <span></span>-->
<!--        </span>-->
<!--    </a>-->
    <div class="navbar-brand logo">
      <img src="../assets/img/uca/uca-logo.svg" v-if="getTheme == 'uca'" class="img-fluid" alt="Logo">
      <img src="../assets/img/esn/esn-logo.png" v-if="getTheme == 'esn'" class="img-fluid" alt="Logo">
    </div>

    <mainnav></mainnav>

  </div>
</template>
<script>
import Helper from "../../src/helpers/helper.service";

export default {
  name: "Navbar",
  computed: {
    getTheme() {
      return Helper.getTheme()
    }
  },
}
</script>
