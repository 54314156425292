<template>
  <div class="menu-header">
    <img src="../assets/img/uca/uca-logo.svg" v-if="getTheme == 'uca'" class="img-fluid p-4" alt="Logo">
    <img src="../assets/img/esn/esn-logo.png" v-if="getTheme == 'esn'" class="img-fluid p-4" alt="Logo">
    <a id="menu_close" class="menu-close" href="javascript:void(0);">
      <i class="fas fa-times"></i>
    </a>
  </div>
</template>

<script>
import Helper from "../helpers/helper.service";

export default {
  name: "menuheader",
  computed: {
    getTheme() {
      return Helper.getTheme()
    }
  },
}
</script>

<style scoped>

</style>
