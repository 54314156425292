<template>
  <layoutsindex></layoutsindex>
  <homebanner></homebanner>
  <featurecourse></featurecourse>
  <trendingcourse></trendingcourse>
  <shareknowledge></shareknowledge>
  <latestblog></latestblog>
  <layouts1></layouts1>
</template>
<script>
export default {
  components: {},
  data() {
    return {}
  },
  mounted() {

  },
  name: 'index'
}

</script>
