import api from "./api.service";
import Helper from "./helper.service";

const chunkSize = 1.8 * 1024 * 1024;

export default {
  async uploadFile(url, file, data = []) {

    if(!file?.name || !file?.size)
      throw new Error('arquivo não reconhecido')

    let result = null;
    const guid = Helper.getGuid();
    const totalChunks = Math.ceil(file.size / chunkSize);

    for (let i = 0; i < totalChunks; i++) {

      const start = i * chunkSize;
      const end = Math.min(start + chunkSize, file.size);
      const chunk = file.slice(start, end);
      const formData = new FormData();

      formData.append('chunk', chunk);
      const arquivoSplit = file.name.split('.')
      formData.append('fileName', arquivoSplit.slice(0, arquivoSplit.length - 1).join("_").replaceAll(' ', '_'));
      formData.append('fileExtension', file.name.split('.').pop().toLowerCase());
      formData.append('guid', guid);
      formData.append('chunkNumber', i + 1);
      formData.append('totalChunks', totalChunks);


      for (const key in data) {
        if (data.hasOwnProperty(key)) {
          formData.append(key, data[key]);
        }
      }

      result = await api.upload(url, formData)
      if (!result.Success)
        throw new Error(Helper.getMessageError(result))

    } // for

    if (result?.Data) return result.Data

    return null

  }
}
