<template>

  <div class="modal fade" data-bs-backdrop="static" data-bs-keyboard="false" :id="galleryModalId">
    <div class="modal-dialog modal-fullscreen" style="padding: 32px 0 0 32px; width: calc(100vw - 32px)">

      <div class="settings-widget">
        <div class="settings-inner-blk p-0">
          <div class="comman-space" style="min-height: calc(100vh - 84px)">

            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">Galeria</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Fechar"></button>
              </div>
              <div class="modal-body" style="min-height: calc(100vh - 260px)">

                <div class="row">
                  <div class="col-lg-6">

                    <div class="form-group">
                      <label class="form-label">Pasta Galeria</label>
                      <MultselectForm
                          :options="PastaLista"
                          v-model="PastaSel"
                          label="PastaNome"
                          placeholder="Selecione uma Pasta"
                          @select="listFiles"
                      />
                    </div>

                  </div>
                  <div class="col-lg-6">

                    <div class="form-group">
                      <label class="add-course-label">Selecione arquivos para incluir na galeria</label>
                      <div class="relative-form">
                        <span>nenhum arquivo selecionado</span>
                        <label class="relative-file-upload">
                          Selecione os Arquivos
                          <input type="file" multiple :id="inputFileId" class="uploadFiles" @click="clickUploadFiles"
                                 @change="uploadFiles">
                        </label>
                      </div>
                    </div>

                  </div>
                </div>
                <div class="gallery-card">
                  <div class="row">
                    <div v-for="(item, index) in Galeria" :key="index" class="col-md-4 col-lg-2">
                      <div class="card file-card" :title="`arquivo criado em ${item.Data}`">

                        <div class="row">
                          <div class="col-sm-4">
                            <div class="form-check" title="selecione este arquivo para incluir no documento">
                              <input class="form-check-input"
                                     type="radio"
                                     name="fileItemRadio"
                                     :checked="false"
                                     @change="selectFile(item)"/>
                            </div>
                          </div>
                          <div class="col-sm-8">
                            <div class="btn-group d-flex">
                              <div class="btn btn-sm btn-outline-dark" title="Download"
                                   @click="downloadFile(item)"><i class="feather-download"></i></div>
                              <div class="btn btn-sm btn-outline-danger" title="Remover"
                                   @click="disableFile(item)"><i class="feather-x"></i></div>
                            </div>
                          </div>
                        </div>

                        <div class="image-icon" :style="`background-image: url('${item.ArquivoLink}')`"
                             v-if="ExtensionImg.includes(item.ArquivoExtensao)"
                        />

                        <i class="file-icon feather-file" v-else></i>

                        <p class="cursor-pointer text-sm" v-if="!item?.Editar" @click="editarFileName(item)">
                          {{ item.ArquivoNome }}.{{ item.ArquivoExtensao }}
                        </p>

                        <input type="text" class="form-control text-sm p-1"
                          v-if="item?.Editar"
                          v-model="item.ArquivoNome"
                          @focusout="() => item.Editar = false"
                          @change="updateFileName(item)"/>

                      </div>
                    </div>
                    <p v-if="Galeria.length == 0" class="text-center text-muted">nenhum arquivo listado nesta pasta</p>

                  </div>
                </div>

              </div>
              <div class="modal-footer">
                <button type="button"
                        class="btn btn-cancel"
                        data-bs-dismiss="modal"
                        :id="btnFecharModalId"
                        @click="fecharModal">
                  Fechar
                </button>
                <button type="button"
                        :class="`btn btn-primary ${(SelectedFile == null)? 'disabled' : ''}`"
                        :disabled="(SelectedFile == null)"
                        @click="incluirArquivo">
                  Incluir Arquivo
                </button>
              </div>

            </div>
          </div>
        </div>

      </div>
    </div>
  </div>

  <button type="button"
          :class="_class"
          :style="_style"
          data-bs-toggle="modal"
          :data-bs-target="`#${galleryModalId}`"
          @click="modalLoad"
          :disabled="disabled">
    {{ label }}
  </button>

</template>

<script>

import disciplinaService from "../../Services/Disciplina.service";
import bancoPerguntaService from "../../Services/BancoPergunta.service";
import galeriaService from "../../Services/Gallery.service";
import Helper from "../../helpers/helper.service";
import fileExtensions from "../../models/file.extensions";
import uploadService from "../../helpers/upload.service";
import endpoints from "../../models/api/api.endpoints";

export default {
  name: "Gallery",
  props: {
    _class: '',
    _style: '',
    disabled: false,
    label: '',
    listar: '',
    idDisciplina: null,
    idBancoPergunta: null,
  },
  emits: [
    "selecionarArquivo"
  ],
  setup() {
    return {}
  },
  data() {
    return {
      tipoListar: null,
      galleryModalId: null,
      inputFileId: null,
      btnFecharModalId: null,
      PastaLista: [],
      PastaSel: [],
      ExtensionImg: fileExtensions.img,
      ExtensionFiles: fileExtensions.mimeImg,
      ExtensionList: [...fileExtensions.img, ...fileExtensions.file],
      MimeTypeList: [...fileExtensions.mimeImg, ...fileExtensions.mimeFile],
      Galeria: [],
      SelectedFile: null,
    }
  },
  created() {

    const guid = Helper.getGuid();

    this.galleryModalId = `galleryModal_${guid}`
    this.inputFileId = `inputFile_${guid}`
    this.btnFecharModalId = `btnFecharModal_${guid}`

    if (!['todos', 'imagens', 'arquivos'].includes(this.listar))
      console.error('O tipo de listagem passado não é tratado pelo sistema')

    this.tipoListar = this.listar;


  },
  methods: {
    async modalLoad() {

      try {

        swalLoading.fire()

        this.PastaLista = [
          {
            Id: null,
            PastaNome: 'Galeria Global'
          },
        ]

        this.Galeria = [];
        this.selectedFile = null;

        if (this.idDisciplina) {

          const result = await disciplinaService.getDisciplina(this.idDisciplina);
          if (!result.Success)
            throw new Error(Helper.getMessageError(result))

          this.PastaLista.push({
            Id: result.Data.Id,
            PastaNome: `Disciplina: ${result.Data.DisciplinaDisplay}`
          })

          this.PastaSel = this.PastaLista[1]

        } else if (this.idBancoPergunta) {

          const result = await bancoPerguntaService.getBancoPergunta(this.idBancoPergunta);
          if (!result.Success)
            throw new Error(Helper.getMessageError(result))

          this.PastaLista.push({
            Id: result.Data.Id,
            PastaNome: `Banco de Pergunta: ${result.Data.BancoPerguntaNome}`
          })

          this.PastaSel = this.PastaLista[1]

        }

        await this.listFiles()

        swalLoading.close()

      } catch (e) {
        Helper.messageExceptionHandler(e)
      }

    },
    async listFiles() {

      try {

        swalLoading.fire()
        this.Galeria = []
        this.SelectedFile = null

        let result = []
        if (this.idDisciplina) {
          result = await galeriaService.getGaleriaLista(null, null, this.PastaSel?.Id)

        } else if (this.idBancoPergunta) {
          result = await galeriaService.getGaleriaLista(null, null, null, this.PastaSel?.Id)

        }

        if (result?.Data) {
          this.Galeria = result.Data.map(item => {
            return {
              ...item,
              Data: Helper.formatDate(item.Data),
              Desativado: (item.Desativado == 1)
            }
          });
        }

        swalLoading.close()

      } catch (e) {
        this.PastaSel = null
        Helper.messageExceptionHandler(e)
      }
    },
    clickUploadFiles(event) {
      if (!this.PastaSel) {
        swalAlert.fire('', 'Selecione uma pasta da galeria para salvar os arquivos', 'warning')
        event.preventDefault();
      }
    },
    async uploadFiles(event) {

      try {

        const data = []
        const files = event.target.files;
        swalLoading.fire('', `Aguarde, realizando upload de ${files.length} arquivo(s)...`);

        let count = 0
        while (files[count]) {

          this.file = files[count];
          if (!this.file) throw new Error(Helper.getMessageError('arquivo não reconhecido|error'))

          const imageData = await Helper.readFile(this.file);

          if (!imageData || !this.MimeTypeList.includes(imageData.file.type))
            throw new Error(`O arquivo "${imageData.file.name}" - ${imageData.file.type} é de um tipo não permitido.
                             Os tipos permitidos são: ${this.ExtensionList.join(', ')}|warning`);

          if (this.PastaSel.Id && this.idDisciplina) {
            data['IdDisciplina'] = this.PastaSel.Id;

          } else if (this.PastaSel.Id && this.idBancoPergunta) {
            data['IdBancoPergunta'] = this.PastaSel.Id;

          }

          await uploadService.uploadFile(endpoints.galeria, this.file, data)

          count++;

        } // while

        await this.listFiles();

        swalToast.fire('Upload', 'arquivos incluídos', 'success')

      } catch (e) {
        Helper.messageExceptionHandler(e)
      }

      document.getElementById(`${this.inputFileId}`).value = null

    },
    async updateFile(item) {

      const data = {
        Id: item.Id,
        ArquivoNome: item.ArquivoNome,
        Desativado: item.Desativado
      };

      const result = await galeriaService.updateArquivoGaleria(data)

    },
    async downloadFile(item) {

      try {

        window.open(item.ArquivoLink)

      } catch (e) {
        Helper.messageExceptionHandler(e)
      }

    },
    editarFileName(item) {
      item.Editar = true;

    },
    async updateFileName(item) {

      try {

        swalLoading.fire('', 'Atualizando nome do arquivo...')
        await this.updateFile(item)
        item.Editar = false
        swalToast.fire('', 'nome de arquivo atualizado', 'success')

      } catch (e) {
        Helper.messageExceptionHandler(e)
      }

    },
    async disableFile(item) {

      try {

        await swalAlert.fire({
          title: 'Remover arquivo?',
          text: '',
          icon: 'question',
          confirmButtonText: 'Remover',
          showCancelButton: true,
          cancelButtonText: 'Cancelar',
        }).then(async (result) => {
          if (result.isConfirmed) {
            swalLoading.fire('', 'Removendo arquivo...')
            item.Desativado = true;
            await this.updateFile(item)
            await this.listFiles();
            swalToast.fire('', 'arquivo removido', 'success')
          }
        })

      } catch (e) {
        Helper.messageExceptionHandler(e)
      }

    },
    selectFile(item) {
      this.SelectedFile = item;
    },
    incluirArquivo() {

      try {

        if (!this.SelectedFile)
          throw new Error("Arquivo não encontrado")

        if (this.tipoListar == 'imagens' && !this.ExtensionImg.includes(this.SelectedFile.ArquivoExtensao))
          throw new Error("É necessário selecionar um arquivo do tipo Imagem para inclusão")

        else if (this.tipoListar == 'arquivos' && !this.ExtensionList.includes(this.SelectedFile.ArquivoExtensao))
          throw new Error("É necessário selecionar um arquivo do tipo Documento para inclusão")

        swalLoading.fire()
        document.getElementById(this.btnFecharModalId).click();
        this.$emit('selecionarArquivo', this.SelectedFile);

        this.PastaLista = [];
        this.PastaSel = null;
        this.SelectedFile = null;
        this.Galeria = [];

        swalLoading.close()

      } catch (e) {
        Helper.messageExceptionHandler(e)
      }
    },
    fecharModal() {
      document.getElementById(`${this.inputFileId}`).value = null;
    }
  },
}
</script>

<style scoped>


</style>
