<template>
  <div id="app">
    <router-view/>
  </div>

  <ModalVideoChamada/>

</template>
<script>

import ModalVideoChamada from '../src/views/pages/Mensagens/ModalVideoChamada';

export default {
  name: 'App',
  components: { ModalVideoChamada },
  data: function () {
    return {status: false}
  },
}
</script>


