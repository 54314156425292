<template>
  <!-- Login Banner -->
  <div :class="`col-md-6 login-bg ${getBackground}`">
    <div class="owl-carousel login-slide owl-theme">
      <div class="welcome-login">
        <div class="login-banner">
          <img v-if="getTheme == 'uca'" src="../assets/img/uca/uca-logo.svg" class="img-fluid" alt="Logo">
          <img v-if="getTheme == 'esn'" src="../assets/img/esn/esn-logo-n.png" class="img-fluid" alt="Logo">
        </div>
        <Carousel>
          <Slide v-for="slide in 3" :key="slide">
            <div class="mentor-course text-center mt-3" v-if="getTheme == 'uca'">
              <h4>Bem vindo à <br>Faculdade Católica Paulista.</h4>
            </div>
            <div class="mentor-course text-center mt-3" v-if="getTheme == 'esn'">
              <h4>Bem vindo à ESN <br> Escola Superior de Educação e Negócios.</h4>
            </div>
          </Slide>
          <template #addons>
            <Pagination/>
          </template>
        </Carousel>
      </div>
    </div>
  </div>
  <!-- /Login Banner -->
</template>
<script>
import {defineComponent} from 'vue'
import {Carousel, Pagination, Slide} from 'vue3-carousel';
import Helper from '../helpers/helper.service'
import 'vue3-carousel/dist/carousel.css';

export default defineComponent({
  name: 'Autoplay',
  components: {
    Carousel,
    Slide,
    Pagination,
  },
  computed: {
    getTheme(){
      return Helper.getTheme()
    },
    getBackground() {
      const theme = Helper.getTheme()
      return (theme == 'uca') ? 'uca-login-bg' : 'esn-login-bg'
    },
  },
});
</script>
