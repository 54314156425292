const extension = {
  img: ['jpeg', 'jpg', 'png', 'webp'],
  mimeImg: ['image/jpeg', 'image/png', 'image/webp'],
  file: ['pdf', 'doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx', 'potx', 'xps', 'vsdx', 'csv', 'zip', 'rar', 'mp4', 'mp3'],
  mimeFile: [
    'application/pdf',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.ms-powerpoint',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'application/vnd.openxmlformats-officedocument.presentationml.template',
    'application/vnd.ms-xpsdocument',
    'application/vnd.visio',
    'text/csv',
    'application/zip',
    'application/x-zip-compressed',
    'application/vnd.rar',
    'application/x-compressed',
    'video/mp4',
    'audio/mpeg'
  ]
}

export default extension;
