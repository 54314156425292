<template>
  <div class="stickysidebar">
    <div class="settings-widget account-settings" v-if="showMenu('academico')">
      <div class="settings-menu">
        <h3>Acadêmico</h3>
        <ul>
          <li class="nav-item">
            <router-link :class="currentPath == 'disciplina' ? 'active' : 'notactive'" to="/academico/disciplina" class="nav-link">
              <i class="feather-star"></i> Disciplinas
            </router-link>
          </li>
          <li class="nav-item">
            <router-link :class="currentPath == 'turma' ? 'active' : 'notactive'" to="/academico/turma" class="nav-link">
              <i class="feather-star"></i> Turmas
            </router-link>
          </li>
        </ul>
      </div>
    </div>
    <div class="settings-widget account-settings" v-if="showMenu('administrativo')">
      <div class="settings-menu">
        <h3>Administrativo</h3>
        <ul>
          <li class="nav-item ">
            <router-link :class="currentPath == 'usuario' ? 'active' : 'notactive'" to="/administrativo/usuario" class="nav-link">
              <i class="feather-star"></i> Usuários
            </router-link>
          </li>
          <li class="nav-item">
            <router-link :class="currentPath == 'perfil' ? 'active' : 'notactive'" to="/administrativo/perfil" class="nav-link">
              <i class="feather-star"></i> Perfil e Permissões
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  computed: {
    currentPath() {
      return this.$route.name;
    },
  },
  methods: {
    showMenu(menu) {
      return (this.$route.matched[1].path == `/${menu}`)
    },
  }
}
</script>

<style scoped>
.main-nav {
  display: inline;
}

</style>
