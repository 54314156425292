<template>
<!-- Share Knowledge -->
<section class="section share-knowledge">
    <div class="container">
        <div class="row">
            <div class="col-md-6">
                <div class="knowledge-img aos" data-aos="fade-up">
                    <img src="../../../assets/img/share.png" alt="" class="img-fluid">
                </div>
            </div>
            <div class="col-md-6 d-flex align-items-center"> 
                <div class="join-mentor aos" data-aos="fade-up">
                    <h2>Want to share your knowledge? Join us a Mentor</h2>
                    <p>High-definition video is video of higher resolution and quality than standard-definition. While there is no standardized meaning for high-definition, generally any video.</p>
                    <ul class="course-list">
                        <li><i class="fa-solid fa-circle-check"></i>Best Courses</li>
                        <li><i class="fa-solid fa-circle-check"></i>Top rated Instructors</li>
                    </ul>
                    <div class="all-btn all-category d-flex align-items-center">
                        <router-link to="/" class="btn btn-primary">Read More</router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- /Share Knowledge -->

<!-- Users Love -->
<section class="section user-love">
    <div class="container">
        <div class="section-header white-header aos" data-aos="fade-up">
            <div class="section-sub-head feature-head text-center">
                <span>Check out these real reviews</span>
                <h2>Users-love-us Don't take it from us.</h2>
            </div>
        </div>
    </div>
</section>
<!-- /Users Love -->

<!-- Say testimonial Four -->
<section class="testimonial-four">
    <div class="review">
        <div class="container">
            <div class="testi-quotes">
                <img src="../../../assets/img/qute.png" alt="" >
            </div>
            <div class="mentor-testimonial lazy slider aos" data-aos="fade-up" data-sizes="50vw ">
                <div class="d-flex justify-content-center">
                    <div class="testimonial-all d-flex justify-content-center">
                        <div class="testimonial-two-head text-center align-items-center d-flex">
                            <div class="testimonial-four-saying ">
                                <div class="testi-right">
                                    <img src="../../../assets/img/qute-01.png" alt="">
                                </div>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                <div class="four-testimonial-founder">
                                    <div class="fount-about-img">
                                        <router-link to="/"><img src="../../../assets/img/user/user1.jpg" alt="" class="img-fluid"></router-link>
                                    </div>
                                    <h3><router-link to="/">Daziy Millar</router-link></h3>
                                    <span>Founder of Awesomeux Technology</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="d-flex justify-content-center">
                    <div class="testimonial-all d-flex justify-content-center">
                        <div class="testimonial-two-head text-center align-items-center d-flex">
                            <div class="testimonial-four-saying ">
                                <div class="testi-right">
                                    <img src="../../../assets/img/qute-01.png" alt="">
                                </div>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                <div class="four-testimonial-founder">
                                    <div class="fount-about-img">
                                        <router-link to="/"><img src="../../../assets/img/user/user3.jpg" alt="" class="img-fluid"></router-link>
                                    </div>
                                    <h3><router-link to="/">john smith</router-link></h3>
                                    <span>Founder of Awesomeux Technology</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="d-flex justify-content-center">
                    <div class="testimonial-all d-flex justify-content-center">
                        <div class="testimonial-two-head text-center align-items-center d-flex">
                            <div class="testimonial-four-saying ">
                                <div class="testi-right">
                                    <img src="../../../assets/img/qute-01.png" alt="">
                                </div>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                <div class="four-testimonial-founder">
                                    <div class="fount-about-img">
                                        <router-link to="/"><img src="../../../assets/img/user/user2.jpg" alt="" class="img-fluid"></router-link>
                                    </div>
                                    <h3><router-link to="/">David Lee</router-link></h3>
                                    <span>Founder of Awesomeux Technology</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- /Say testimonial Four -->

<!-- Become An Instructor -->
<section class="section become-instructors aos" data-aos="fade-up">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-6 d-flex">
                <div class="student-mentor cube-instuctor ">
                    <h4>Become An Instructor</h4>
                    <div class="row">
                        <div class="col-lg-7 col-md-12">
                            <div class="top-instructors">
                                <p>Top instructors from around the world teach millions of students on Mentoring.</p>
                            </div>
                        </div>
                        <div class="col-lg-5 col-md-12">
                            <div class="mentor-img">
                                <img class="img-fluid" alt="" src="../../../assets/img/become-02.png">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 d-flex">
                <div class="student-mentor yellow-mentor">
                    <h4>Transform Access To Education</h4>
                    <div class="row">
                        <div class="col-lg-8 col-md-12">
                            <div class="top-instructors">
                                <p>Create an account to receive our newsletter, course recommendations and promotions.</p>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-12">
                            <div class="mentor-img">
                                <img class="img-fluid" alt="" src="../../../assets/img/become-01.png">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- /Become An Instructor -->
</template>
<script>
     import AOS from 'aos';
	export default {
	mounted() {
        if($('.mentor-testimonial.lazy').length > 0) {
		$(".mentor-testimonial.lazy").slick({
			lazyLoad: 'ondemand',
			infinite: true
		});
	}
    if($('.main-wrapper .aos').length > 0) {
            AOS.init({
              duration: 1200,
              once: true,
            });
        }

		},
       
	data() {
        return {
           
        }
    }
	}
</script>