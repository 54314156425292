<template>
<!-- Footer Bottom -->
<div class="footer-bottom">
    <div class="container">

        <!-- Copyright -->
        <div class="copyright">
            <div class="row">
                <div class="col-md-6">

                </div>
                <div class="col-md-6">

                </div>
            </div>
        </div>
        <!-- /Copyright -->

    </div>
</div>
<!-- /Footer Bottom -->
</template>
