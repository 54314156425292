import endpoints from "../models/api/api.endpoints";
import api from "../helpers/api.service";
import Helper from "../helpers/helper.service";
import ServiceResponseCode from "../models/service.response.code";

export default {
  async getGaleriaLista(
    page = null,
    pageSize = null,
    idDisciplina = null,
    idBancoPergunta = null
  ) {
    try {
      const params = [
        {
          Name: 'page',
          Value: page
        },
        {
          Name: 'pageSize',
          Value: pageSize
        },
        {
          Name: 'IdDisciplina',
          Value: idDisciplina
        },
        {
          Name: 'IdBancoPergunta',
          Value: idBancoPergunta
        }
      ]
      const url = `${endpoints.galeria}/list${Helper.getURLParams(params)}`;
      const result = await api.get(url)
      return Helper.getServiceResponse(result)
    } catch (e) {
      return {
        Code: ServiceResponseCode.CatchAPICall,
        Title: 'Error',
        Message: e.message,
        Success: false,
      }
    }
  },
  async getGaleria(id) {
    try {
      const url = `${endpoints.galeria}/${id}`;
      const result = await api.get(url)
      return Helper.getServiceResponse(result)
    } catch (e) {
      return {
        Code: ServiceResponseCode.CatchAPICall,
        Title: 'Error',
        Message: e.message,
        Success: false,
      }
    }
  },
  async updateArquivoGaleria(data) {
    try {
      const result = await api.put(endpoints.galeria, data)
      return Helper.getServiceResponse(result)
    } catch (e) {
      return {
        Code: ServiceResponseCode.CatchAPICall,
        Title: 'Error',
        Message: e.message,
        Success: false,
      }
    }
  },

}
