<template>
  <ul class="main-nav">
    <li v-bind:class="{'active': currentPath == 'index' || currentPath == 'index'}">
      <router-link to="/">Home</router-link>
    </li>
    <li class="login-link">
      <router-link to="/login">Login / Signup</router-link>
    </li>
  </ul>
</template>
<script>
export default {
  components: {},
  data() {
    return {}
  },
  mounted() {

  },
  computed: {
    currentPath() {
      return this.$route.path
    },
  },
  methods:{
    currentActive(){
      return 'notactive' // 'active' : 'notactive'
    },
  }
}
</script>
