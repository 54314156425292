const ServiceResponseCode = {
  ResponseNotFound: -3,
  SendFieldNotFound: -2,
  CatchAPICall: -1,
  InactiveSession: 1,

  Success: 0,
  Catch_Generic: 1,
  Catch_ValidateLogin: 2,
  TokenAccess: 3,
  LogInternalProcessError: 4,
  PermissionAccessError: 5,

}

export default ServiceResponseCode
