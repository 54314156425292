<template>
  <div class="settings-widget">
    <div class="settings-inner-blk p-0">
      <div class="sell-course-head comman-space">
        <h3 class="text-muted">{{ _title }}</h3>
        <p class="text-muted" v-if="description">{{ description }}</p>
      </div>
      <div class="form-title" v-if="$slots.title">
        <slot name="title"></slot>
      </div>
      <div class="comman-space pb-0">
        <div class="instruct-search-blk">

          <div class="show-filter mb-3" v-if="$slots.filter">
            <slot name="filter"></slot>
          </div>

          <slot name="content"></slot>

        </div>
        <div class="settings-tickets-blk course-instruct-blk table-responsive">
        </div>
      </div>
      <div class="form-menu text-end" v-if="enableFooter">
        <slot name="submenu"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import {useRoute} from "vue-router";

export default {
  name: "DefaultPage",
  props: {
    title: null,
    description: null,
    enableFooter: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    _title() {
      if (!this.title) {
        const route = useRoute()
        return route.name
      }
      return this.title
    }
  }
}
</script>

<style scoped>

</style>
