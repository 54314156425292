<template>

  <div class="modal fade" id="ModalVideoChamada" data-bs-backdrop="static"
       data-bs-keyboard="false">
    <div class="modal-dialog modal-lg">
      <div class="settings-inner-blk p-0">
        <div class="sell-course-head comman-space">
          <div class="modal-content border-0">
            <div class="modal-header">
              <h5 class="modal-title">Vídeo Chamada</h5>
              <button type="button" class="btn-close" @click="fecharModalVideoChamada"></button>
            </div>
            <div class="modal-body">

              <div class="text-center py-5 loading-center" v-if="isLoading">
                <div class="spinner-border text-primary" role="status">
                  <span class="visually-hidden">Carregando...</span>
                </div>
                <div class="spinner-text text-muted text-center">
                  Carregando...
                </div>
              </div>

              <div v-if="!isLoading">

                <div class="mb-3">
                  <label class="form-label">Contato</label>
                  <div class="form-group">

                    <MultselectForm
                        :options="UsuarioLista"
                        v-model="UsuarioSel"
                        label="DisplayNome"
                        placeholder="Pesquise o contato"

                        :searchable="true"
                        @search-change="pesquisaUsuario"
                        :loading="pesquisaUsuario_isLoading"
                        :limit="10"

                    />
                  </div>
                </div>

              </div>
              <div class="modal-footer">
                <button type="button"
                        class="btn btn-cancel"
                        data-bs-dismiss="modal"
                        id="btnModalFecharVideoChamada"
                        @click="fecharModalVideoChamada">
                  Fechar
                </button>
                <button type="button" class="btn btn-primary"
                        @click="iniciarVideoChamada">
                  <i class="fa-solid fa-phone"/> Iniciar Vídeo Chamada
                </button>
              </div>

            </div>

          </div>
        </div>
      </div>
    </div>
  </div>

  <button class="display-off" id="btnModalVideoChamada"
          data-bs-toggle="modal"
          data-bs-target="#ModalVideoChamada"
          @click="abrirModalVideoChamada">
  </button>

</template>

<script>

import mensagemService from '../../../Services/Mensagem.service'
import Helper from "../../../helpers/helper.service";

export default {
  name: "ModalMensagem",
  emits: ['reloadMensagemSel'],
  data() {
    return {
      timeOut: null,
      isLoading: false,
      usuario: null,
      pesquisaUsuario_isLoading: false,
      UsuarioLista: [],
      UsuarioSel: [],
    }
  },
  async created() {

  },
  methods: {
    async abrirModalVideoChamada() {

      try {

        this.isLoading = true
        this.limpar()
        await this.pesquisaUsuario()
        this.isLoading = false

      } catch (e) {
        Helper.messageExceptionHandler(e)
      }

    },
    async pesquisaUsuario(pesquisa = null) {

      try {

        this.pesquisaUsuario_isLoading = true
        this.UsuarioLista = []

        await clearTimeout(this.timeOut)

        this.timeOut = setTimeout(async () => {

          const result = await mensagemService.listaContatos(11, pesquisa);
          if (!result.Success)
            throw new Error(Helper.getMessageError(result))

          this.UsuarioLista = result.Data

          this.pesquisaUsuario_isLoading = false

        }, (pesquisa) ? 600 : 0);

      } catch (e) {
        Helper.messageExceptionHandler(e)
      }

    },
    limpar() {

      this.UsuarioLista = []
      this.UsuarioSel = null

    },
    async iniciarVideoChamada() {

      try {

        if (!this.UsuarioSel || this.UsuarioSel?.length == 0)
          throw new Error('Selecione o contato|warning')

        window.open(this.UsuarioSel.LinkChamadaVideo)

        this.limpar()
        this.fecharModalVideoChamada();

      } catch (e) {
        Helper.messageExceptionHandler(e)
      }

    },
    fecharModalVideoChamada() {
      document.getElementById('btnModalFecharVideoChamada').click()
    },
  },
}
</script>

<style scoped>

</style>