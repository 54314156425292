const ModoAluno = {
  methods: {
    checkModoAluno() {
      return (window.localStorage.getItem("idModoAluno")) ? true : false
    },
    getNomeModoAluno() {
      return window.localStorage.getItem("nomeModoAluno")
    },
    async sairModoAluno() {

      await swalAlert.fire({
        title: '',
        text: 'Sair do modo aluno?',
        icon: 'question',
        confirmButtonText: 'Confirmar',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
      }).then(async (result) => {
        if (result.isConfirmed) {
          window.localStorage.removeItem('idModoAluno')
          window.localStorage.removeItem('nomeModoAluno')
          await this.$router.push(`/administrativo/aluno`)
          window.location.reload()
        }
      });

    },
  }
}

export default ModoAluno