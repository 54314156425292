<template>
    <ul class="nav header-navbar-rht">
        <li class="nav-item">
            <router-link class="nav-link header-sign" to="login">Signin</router-link>
        </li>
<!--        <li class="nav-item">-->
<!--            <router-link class="nav-link header-login" to="registrar">Signup</router-link>-->
<!--        </li>-->
    </ul>
</template>
