<template>
  <ul class="nav header-navbar-rht">

    <li class="nav-item user-nav">
      <a href="javascript:void(0)" class="dropdown-toggle" data-bs-toggle="dropdown">
          <span class="user-img">
              <img v-if="usuario && usuario.Foto" :src="usuario.Foto" alt=""/>
              <img v-else-if="getTheme == 'uca'" src="../assets/img/uca/avatar-icon.png" alt=""/>
              <img v-else-if="getTheme == 'esn'" src="../assets/img/esn/avatar-icon.png" alt=""/>
              <span class="status online"></span>
          </span>
        <div class="user-header float-end px-0">
          <div class="user-text" v-if="usuario">
            <h6 class="m-0">{{ usuario.Nome }}</h6>
            <p class="text-muted mb-0 text-xs" v-if="usuario.Admin">Administrador</p>
            <p class="text-muted mb-0 text-xs" v-else>{{ usuario.Email }}</p>
          </div>
        </div>
      </a>
      <div class="users dropdown-menu dropdown-menu-right" data-popper-placement="bottom-end">
        <a class="dropdown-item cursor-pointer" @click="this.$router.push('/perfil')"><i class="feather-user me-1"></i>
          Perfil</a>
        <a class="dropdown-item cursor-pointer" @click="alterarSenha"><i class="feather-lock me-1"></i> Alterar
          Senha</a>
        <a class="dropdown-item cursor-pointer" @click="logout"><i class="feather-log-out me-1"></i> Sair</a>
      </div>
    </li>

    <li class="nav-item noti-nav" v-if="temPermissaoMensagem">
      <a href="javascript:void(0)" class="dropdown-toggle" data-bs-toggle="dropdown"
         :title="(MensagensNotificacao > 0) ? 'Você tem novas notificações' : 'Nenhuma notificação'">
        <img src="../assets/img/icon/notification.svg" alt="img" v-if="(MensagensNotificacao > 0)">
        <img src="../assets/img/icon/notification.svg" alt="img" v-else style="filter: grayscale(100%);">
      </a>
      <div class="notifications dropdown-menu dropdown-menu-right">
        <div class="topnav-dropdown-header">
          <span class="fw-bold text-center" v-if="MensagensNotificacao > 0">{{ MensagensNotificacao }} Mensagens não lidas</span>
          <span class="text-muted text-center" v-else>Todas mensagens lidas</span>
          <router-link to="/aluno/mensagens" class="clear-noti">
            <div>Ir para Mensagens <i class="fa-solid feather-chevron-right"/></div>
          </router-link>
        </div>
        <!--        <div class="noti-content">-->
        <!--          <ul class="notification-list">-->

        <!--            <li class="notification-message"-->
        <!--                v-for="(notificacao, index) in MensagensNotificacao" :key="index">-->
        <!--              <div class="media d-flex">-->
        <!--                <div class="avatar">-->
        <!--                  <img v-if="notificacao.FotoRemetente" :src="notificacao.FotoRemetente" class="avatar-img"/>-->
        <!--                  <img v-else-if="getTheme == 'uca'" src="../assets/img/uca/avatar-icon.png" class="avatar-img"/>-->
        <!--                  <img v-else-if="getTheme == 'esn'" src="../assets/img/esn/avatar-icon.png" class="avatar-img"/>-->
        <!--                </div>-->
        <!--                <div class="media-body">-->
        <!--                  <h6>{{ notificacao.DisplayNomeRemetente }}</h6>-->
        <!--                  <p class="noti-details">{{ notificacao.Assunto }}</p>-->
        <!--                  <p class="text-xs text-muted m-0">{{ notificacao.CriadoEm }}</p>-->
        <!--                </div>-->
        <!--              </div>-->
        <!--            </li>-->

        <!--          </ul>-->
        <!--        </div>-->
      </div>
    </li>

  </ul>
</template>
<script>
import authService from "../Services/Auth.service";
import userService from "../helpers/user.service.js";
import Helper from "../helpers/helper.service";
import mensagemService from '../Services/Mensagem.service'

export default {
  components: {},
  data() {
    return {
      usuario: null,
      MensagensNotificacao: [],
    }
  },
  async mounted() {
    this.usuario = userService.getUser()

    if (this.temPermissaoMensagem) {

      await this.getNotificacao()

      setInterval(async () => {
        if (userService.isAuthenticated()) {
          await this.getNotificacao()
        }
      }, 60000); // 1 min

    }

  },
  computed: {
    temPermissaoMensagem() {
      return userService.checkPermision('Mensagem')
    },
    getTheme() {
      return Helper.getTheme()
    }
  },
  methods: {
    async logout() {
      try {

        swalLoading.fire('', 'Realizando logout, aguarde...')

        const result = await authService.Logout();

        swalLoading.close();
        await this.$router.push('/login')

      } catch (e) {
        Helper.messageExceptionHandler(e)
      }
    },
    alterarSenha() {
      this.$router.push('/perfil/alterar-senha')
    },
    async getNotificacao() {

      try {

        const result = await mensagemService.getNotificacaoNaoLidas()
        if (!result.Success)
          throw new Error(Helper.getMessageError(result))

        this.MensagensNotificacao = parseInt(result.Data)

      } catch (e) {
        Helper.messageExceptionHandler(e)
      }

    }
  },
}
</Script>

<style scoped>

.header-navbar-rht .dropdown-menu {
  min-width: 220px;
  padding: 0;
}

.topnav-dropdown-header > span {
  cursor: default;
}

.notification-message:hover {
  cursor: default;
  background-color: #f6f6f6;
}

</style>
